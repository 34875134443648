import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'
import format from 'date-fns/format'
import { subDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import '../../styles/style.css'
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Card, CardActionArea, CardContent, CardHeader, Checkbox, CircularProgress, Grid,
    IconButton, Tooltip
} from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupsIcon from '@mui/icons-material/Groups';
import { CardButton, SessionButton } from '../../styles/styles'
import { useSelector } from 'react-redux'
import moment from "moment/moment";
export const DateRangePick = ({ handleSelectedSession, handlePiData,
    handlePlayer, handleselectedPlayers, piKey, handleDateChangefn }) => {
    const session_details = useSelector(state => state.dashboard.sessionList)
    const session_Pi_details = useSelector(state => state.dashboard.piSessionList)
    const [samplePiMatch, setSamplePiMatch] = useState([]);
    const [piData, setPiData] = useState([])
    const [sampleMatch, setSampleMatch] = useState([]);
    const [open, setOpen] = useState(false)
    const [sessionData, setSessionData] = useState([])
    const [sessionPiData, setSessionPiData] = useState([])
    const [selectedSessions, setSelectedSessions] = useState([])
    const [doneAll, setDoneAll] = useState(false)
    const [multipleSessionData, setMultipleSessionData] = useState([])
    const [range, setRange] = useState([
        {
            startDate: subDays(new Date(), 5),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    // // get the target element to toggle 
    const refOne = useRef(null)
    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)

    }, [])
    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    useEffect(() => {
        let startDate = range[0].startDate;
        let endDate = range[0].endDate;
        let data = {
            sessionStartDate: moment(startDate).utcOffset(0, true).format(),
            sessionEndDate: moment(endDate).utcOffset(0, true).format()
        }
        handleDateChangefn(data)
    }, [range])
    useEffect(() => {
        if (!doneAll) {
            if (!session_details.isFetching && !session_details.error && session_details.payload.data) {
                if (Array.isArray(session_details.payload.data.session_data)) {
                    setSessionData(session_details.payload.data.session_data)
                    const temp = [...session_details.payload.data.session_data].map((item, index) => {
                        return {
                            sessionName: item.sessionName,
                            startTime: moment(item.sessionDetails.startTime).local().format('h:mm a'),
                            endTime: moment(item.sessionDetails.endTime).local().format('h:mm a'),
                            sessionType: item.sessionDetails.sessionType,
                            sessionId: item.id,
                            playerCount: item.sessionPlayers.length,
                            checkbox: 0
                        }
                    })
                    setSampleMatch(temp)
                }
                else {
                    setSampleMatch([])
                }
            }
        }
        else {
            if (!session_details.isFetching && !session_details.error && session_details.allSessions) {
                if (Array.isArray(session_details.allSessions && session_details.payload.data.session_data)) {
                    const tempSessionData = [...session_details.allSessions].reduce((acc, cur) => {
                        if (!acc.find((item) => item.id === cur.id)) {
                            acc.push(cur)
                        }
                        return acc;
                    }, [])
                    setSessionData(tempSessionData);
                    const temp = [...session_details.payload.data.session_data].map((item, index) => {
                        return {
                            sessionName: item.sessionName,
                            startTime: moment(item.sessionDetails.startTime).local().format('h:mm a'),
                            endTime: moment(item.sessionDetails.endTime).local().format('h:mm a'),
                            sessionType: item.sessionDetails.sessionType,
                            sessionId: item.id,
                            playerCount: item.sessionPlayers.length,
                            //checkbox: index === 0
                        }
                    })
                    setSampleMatch(temp)
                }
            }
        }
    }, [session_details])
    useEffect(() => {
        if (!session_Pi_details.isFetching && !session_Pi_details.error && session_Pi_details.payload.data) {
            if (Array.isArray(session_Pi_details.payload.data.session_data)) {
                setSessionPiData(session_Pi_details.payload.data.session_data)
                const temp = [...session_Pi_details.payload.data.session_data].map((item, index) => {
                    return {
                        sessionName: item.sessionName,
                        startTime: moment(item.sessionDetails.startTime).local().format('h:mm a'),
                        endTime: moment(item.sessionDetails.endTime).local().format('h:mm a'),
                        sessionType: item.sessionDetails.sessionType,
                        sessionId: item.id,
                        playerCount: item.sessionPlayers.length,
                        // checkbox: index === 0
                    }
                })
                const tempPi = [...temp].slice(0, 1)
                setSamplePiMatch(temp)
                setPiData(tempPi)

            } else {
                setSamplePiMatch([])
            }
        }
    }, [session_Pi_details])
    const handleSelectedData = (e, id) => {
        let sampleSelectedData = [...sampleMatch];
        sampleSelectedData.forEach((item) => {
            if (item.sessionId === id) {
                item.checkbox = !item.checkbox
            }
            return item;
        })
        setSampleMatch(sampleSelectedData)
        if (doneAll) {
            if (getMultipleChecked(id)) {
                const checked = multipleSessionData.filter((item) => {
                    return item.sessionId !== id
                })
                setMultipleSessionData(checked)
            }

            
            else{
            let dups = [];
            let arr = [];
            let sample = [...multipleSessionData, ...sampleSelectedData];
            sample.forEach(item => {
                if (item.checkbox && dups.indexOf(item.sessionId) === -1) {
                    arr.push(item);
                    dups.push(item.sessionId);
                }
            })
            setMultipleSessionData(arr);
        }

        }

    }
    const getMultipleChecked = (id) => {
        
        const found = [...multipleSessionData].find(item => item.sessionId === id)
        return found || false;
    }
    const getChecked = (id) => {
        const found = [...piData].find(item => item.sessionId === id)
        return found || false;
    }
    const handleSelectedPiData = (e, data) => {
        if (getChecked(data.sessionId)) {
            const checked = piData.filter((item) => {
                return item.sessionId !== data.sessionId
            })
            setPiData(checked)
        }
        else {
            if (piData.length >= 1) {
                alert("maximum  selection limit is 1")
            }
            else {
                let temp = [...piData];
                temp.push(data)
                setPiData(temp)
            }
        }

    }
    const handleSavePi = () => {
        const temp = [];
        [...piData].forEach((item) => {
            [...sessionPiData].forEach((obj) => {
                if (item.sessionId === obj.id) {
                    temp.push(obj)
                }
            })
        })
        let tempPlayers = []
        temp.forEach((item) => (item.sessionPlayers).forEach((item) => tempPlayers.push(item)))
        const selectedPlayersList = [...tempPlayers].map((item) => {
            return {
                player: item.playerDetails,
                id: item.id,
                isSelected: true
            }
        })
        setOpen(false)
        handlePiData && handlePiData(temp, selectedPlayersList);
    }
    const handleSave = () => {
        let temp = [];
        if (!doneAll) {
            [...sampleMatch].forEach((item) => {
                if (item.checkbox === true) {
                    [...sessionData].forEach((obj) => {
                        if (item.sessionId === obj.id) {
                            temp.push(obj)
                        }
                    })
                }
            })
            setSelectedSessions(temp)
        }
        else {
            [...multipleSessionData].forEach((item) => {
                if (item.checkbox === true) {
                    [...sessionData].forEach((obj) => {
                        if (item.sessionId === obj.id) {
                            temp.push(obj)
                        }
                    })
                }
            })
            setSelectedSessions(temp)
        }
        let tempPlayers = []
        temp.forEach((item) => (item.sessionPlayers).forEach((item) => tempPlayers.push(item)))
        const selectedPlayersList = [...tempPlayers].map((item) => {
            return {
                player: item.playerDetails,
                name: item.playerDetails.firstName + item.playerDetails.lastName,
                id: item.id,
                isSelected: true
            }
        })
        const selectedPlayers = selectedPlayersList.reduce((acc, cur) => {
            if (!acc.find((item) => item.name === cur.name)) {
                acc.push(cur)
            }
            return acc;
        }, [])
        setOpen(false)
        handleSelectedSession && handleSelectedSession(temp);
        handlePlayer && handlePlayer(selectedPlayers)
        handleselectedPlayers && handleselectedPlayers(selectedPlayers)
    }
    const handleDoneAll =() =>{
        setDoneAll(!doneAll);
        // if(!doneAll){
        //     setMultipleSessionData([])
          
        // }
    }
    const handleClear = () => {
        let sampleSelectedData = [...sampleMatch];
        sampleSelectedData.forEach((item) => {
            item.checkbox = false;
        })
        setSampleMatch(sampleSelectedData);
        let sampleMultipleSelectedData = [...multipleSessionData];
        sampleMultipleSelectedData.forEach((item) => {
            item.checkbox = false;
        })
        setMultipleSessionData(sampleMultipleSelectedData);
        getMultipleChecked(0)

    }
    const close = () => {
       setOpen(false)
    }
    return (
        <div className="calendarWrap">
            <Grid container>
                <Grid item>
                    <input
                        value={`${format(range[0].startDate, "MM/dd/yyyy")} To ${format(range[0].endDate, "MM/dd/yyyy")}`}
                        readOnly
                        className="inputBox"
                        onClick={() => setOpen(open => !open)}
                    />
                </Grid>
                <Grid item>
                    <IconButton  onClick={() => setOpen(open => !open)}>
                    <ArrowDropDownIcon sx={{ color: "white" }} />
                    </IconButton>
                </Grid>
            </Grid>
            <div ref={refOne}>
                {open &&
                    <Grid container className={"calendarWrap"} >
                        <Grid item xs={12} sm={6} md={12}>
                            <DateRange sx={{ mt: 10 }}
                                onChange={item => setRange([item.selection])}
                                ranges={range}
                                months={1}
                                rangeColors={["#882BBD"]}
                                className={piKey ? "calendarElement_pichart" : "calendarElement"}
                            />
                        </Grid>
                        <Grid item className={piKey ? "match_selection_box_pichart" : "match_selection_box "} >
                            <Card className='match_selection_card'>
                                <CardHeader
                                    action={
                                        <Grid container>
                                            {
                                                (!piKey) ?
                                                    <Grid item>
                                                        <Tooltip title={!doneAll ? "TURN ON MULTISELECT" : "TURN OFF MULTISELECT"}>
                                                            <IconButton onClick={() => handleDoneAll()}
                                                            >
                                                                <DoneAllIcon
                                                                    className={doneAll ? "show_active" : null} sx={{ color: "white" }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid> : null}
                                            <Grid item >
                                                <IconButton
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <CloseIcon className='userProfile' />
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                    }
                                />
                                <CardContent sx={{ pt: 0 }}>
                                    <Grid >
                                        <p>#Sessions</p>
                                    </Grid>
                                    <div className='divider_daterangepick'></div>
                                    {session_details.isFetching || session_Pi_details.isFetching ? <> <CircularProgress size="30px" className='show_active margin_progress ' /></> :
                                        <Grid container  >
                                            {(!piKey) ?
                                                <>
                                                    {sampleMatch.map((item, index) => (
                                                        <Grid item className='match-list' key={index}>
                                                            <Grid container className=' match-list-elements' >
                                                                <Grid item >
                                                                    {(!doneAll)?
                                                                        <Checkbox
                                                                            sx={{
                                                                                mt: 2,
                                                                                color: " white",
                                                                                '&.Mui-checked': {
                                                                                    color: "#882BBD",
                                                                                    backgroundColor: "#2B3040",
                                                                                },
                                                                            }}
                                                                            checked={item.checkbox}
                                                                            onChange={(e) => { handleSelectedData(e, item.sessionId) }}
                                                                        />:
                                                                        <Checkbox
                                                                            sx={{
                                                                                mt: 2,
                                                                                color: " white",
                                                                                '&.Mui-checked': {
                                                                                    color: "#882BBD",
                                                                                    backgroundColor: "#2B3040",
                                                                                },
                                                                            }}
                                                                            checked={getMultipleChecked(item.sessionId)}
                                                                            onChange={(e) => { handleSelectedData(e, item.sessionId) }}
                                                                        />
                                                                    }
                                                                </Grid>
                                                                <Grid item sx={{ m: 1 }}>
                                                                    <IconButton>

                                                                        <p className='select'>

                                                                            {item.sessionName}
                                                                        </p>
                                                                    </IconButton>
                                                                    <IconButton >
                                                                        <GroupsIcon sx={{ color: "white", mt: "3px", mr: 1 }} fontSize="small" />
                                                                        <p className='select'>{item.playerCount}</p>
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item sx={{ ml: 2 }}>
                                                                    <SessionButton variant='contained'>
                                                                        {item.sessionType}
                                                                    </SessionButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </> : <>   {samplePiMatch.map((item, index) => (
                                                    <Grid item className='match-list' key={index}>
                                                        <Grid container className=' match-list-elements' >
                                                            <Grid item >
                                                                <Checkbox
                                                                    sx={{
                                                                        mt: 2,
                                                                        color: " white",
                                                                        '&.Mui-checked': {
                                                                            color: "#882BBD",
                                                                            backgroundColor: "#2B3040",
                                                                        },
                                                                    }}
                                                                    checked={getChecked(item.sessionId)}
                                                                    onChange={(e) => { handleSelectedPiData(e, item) }}
                                                                />
                                                            </Grid>
                                                            <Grid item sx={{ m: 1 }}>
                                                                <IconButton>
                                                                    <p className='select'>
                                                                        {item.sessionName}
                                                                    </p>
                                                                </IconButton>
                                                                <IconButton >
                                                                    <GroupsIcon sx={{ color: "white", mt: "3px", mr: 1 }} fontSize="small" />
                                                                    <p className='select'>{item.playerCount}</p>
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item sx={{ ml: 2 }}>
                                                                <SessionButton variant='contained'>
                                                                    {item.sessionType}
                                                                </SessionButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}</>
                                            }
                                        </Grid>
                                    }
                                </CardContent>
                                <CardActionArea>
                                    <Grid >
                                        <CardButton variant="contained"
                                            size="small"
                                            onClick={(!piKey) ? () => handleClear() : () => close()}
                                            sx={{ ml: 20, mt: 1, mb: 2 }}>
                                            close
                                        </CardButton>
                                        <CardButton variant="contained"
                                            size="small"
                                            sx={{ ml: 2, mt: 1, mb: 2 }}
                                            onClick={(!piKey) ? () => handleSave() : () => handleSavePi()}
                                        >
                                            OK
                                        </CardButton>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                }
            </div>
        </div>
    )
}



